import { attendaceData, attendanceTableHeadings } from "../../Constant/grades";
import ReactDatePicker from "../../Components/Common/ReactDatePicker";
import AttendanceTable from "../../Components/Common/AttendanceTable";
import { Link } from "react-router-dom";

const Attendance = () => {
  return (
    <div className="container-fluid px-2 py-2 shadow-md bg-white rounded">
      <div className="row">
        <div className="col-md-9  ">
          <h4 className="fw-bold heading24px">8th Class Students</h4>
        </div>
        <div className="col-md-3 ">
          <div className="w-100  d-flex align-items-center h-100 gap-3  justify-content-end">
            <h6 className="p-0 m-0">Date</h6>
            {/* <input type="date" className="" form-control /> */}
            <ReactDatePicker placeholder="Select Date" />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <AttendanceTable
            headings={attendanceTableHeadings}
            data={attendaceData}
          />
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-center justify-content-md-end gap-3">
            <Link
            to="/class-management"
              className="btnFooter"
              style={{ backgroundColor: "#EDEBF1", color: "#463C77" }}
            >
              Cancel
            </Link>
            <Link
            to="/class-management"
              className="btnFooter"
              style={{ backgroundColor: "#463C77", color: "white"  }}
            >
              Save Attendance
            </Link>
            <Link
              to="https://wiser-hub.wiserbee.ca/join/Math101"
              target="_blank"
              className="btnFooter"
              style={{ backgroundColor: "#463C77", color: "white" }}
            >
              Start Leacture
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Attendance;
