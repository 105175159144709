import React, { useEffect, useState } from "react";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { bookSvg, calanderSvg, studentSvg } from "../../Constant/svgs";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../services";
import Modal from 'react-modal';
import EditLessonPlan from "../../modals/EditLessonPlan";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    border: "none",
    overFlow: "auto",
  },
};
const LessonPlans = () => {
  const navigate = useNavigate();
  const [edit,setEdit] = useState(false);
  const [lessonID,setId] = useState(null);
  const [lessonIndex,setIndex] = useState(null);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "sa";
  const [myData, setMyData] = useState([]);
  console.log("myData",myData)
  const fetchData = async () => {
    try {
      const response = await getRequest("/courses/lesson_plan/");
      const data = await response.json();
      setMyData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {

    fetchData();
  }, []);
   const handleClickItem = (id,index) =>{
    setId(id);
    setIndex(index)
    setEdit(true);
   }

  return (
   <>
   <Modal isOpen={edit} onRequestClose={()=>setEdit(false)} style={customStyles}>
    <EditLessonPlan onRequestClose={()=>setEdit(false)} lessonID={lessonID} lessonIndex={lessonIndex} fetchData={fetchData}/>
   </Modal>
    <div className="container-fluid">
      <div className="row p-0 m-0">
        <div className="col-md-12 p-0 m-0">
          <div
            className={`leassonPlanheader d-flex justify-content-between ${isArabic ? "flex-row-reverse" : ""}`}
          >
            <h4 className="p-0 m-0 fw-bold">{t("MyLessonPlans")}</h4>
            <div className="d-flex justify-content-center justify-content-lg-end">
              <button
                className={`text-capitalize fs-6 gap-3 d-flex justify-content-between align-items-center btnWithIcon bg-main ${isArabic ? "flex-row-reverse" : ""}`}
                onClick={() => navigate("/add-lesson-plan")}
              >
                <span className="px-1 py-1 fw-4 rounded p-0 addButtonSty">
                  <Plus />
                </span>
                <span>{t("addNew")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row text-capitalize py-2">
      {myData.map((lesson) => {
        return lesson.week_details.flatMap((week, weekIndex) =>
          week.map((exam, index) => (
            <div className="col-md-6 col-lg-4 cursor-pointer py-2" key={`${lesson.id}-${weekIndex}-${index}`} onClick={() => handleClickItem(lesson.id,index)}>
              <div className="d-flex flex-column align-items-between bg-white examDataWrapper px-2 py-3 borderRadius_15">
                <h4 className="fw-bold p-0 m-0 fs-6 cursor-pointer px-2">
                {exam.topic} 
                </h4>
                <div className="d-flex justify-content-between examChipsWrapper mt-3 px-2">
                  {/* {Array.isArray(exam.tags) &&
                  exam.tags.map((tag, idx) => ( */}
                  <div className="examChip" >
                    Leacture
                  </div>
                  <div className="examChip" >
                    Class {lesson.standard}
                  </div>
                  <div className="examChip" >
                    Section {lesson.section}
                  </div>
                  {/* ))} */}
                </div>
                <div className="d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2">
                  <div className="d-flex gap-2 align-items-center">
                    {calanderSvg} <span className="p-0 m-0">{lesson.lecture_days[index].day}</span>
                  </div>
                  {/* <div className="d-flex gap-2 align-items-center">
                    {studentSvg} <span className="m-0 p-0">{exam.students} Students</span>
                  </div> */}
                  <div className="d-flex gap-2 align-items-center cursor-pointer">
                    {bookSvg} <span>{lesson.subject}</span>
                  </div>
                  <div className="d-flex gap-2 align-items-center cursor-pointer">
                    {calanderSvg} <span>{exam.date}</span>
                  </div>
                </div>
                <div className="d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2">
                  <div className="d-flex gap-2 align-items-center">
                    <span>Subject</span>
                    <p className="p-0 m-0">{lesson.subject}</p>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <span>Start Time</span>
                    <p className="p-0 m-0">{lesson.lecture_days[index].start_time}</p>
                  </div>
                  <div className="d-flex gap-2 align-items-center cursor-pointer">
                    <span>End Time</span>
                    <p className="p-0 m-0">{lesson.lecture_days[index].end_time}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        );
      })}
      </div>
    </div>
   </>
  );
};

export default LessonPlans;
